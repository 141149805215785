import { WhatsApp } from "@mui/icons-material";
import React from "react";

function WhatsappIcon() {
  return (
    <a className="sn-whatsapp" href="https://wa.me/+256753018802">
      <WhatsApp sx={{ fontSize: "43px" }} />
    </a>
  );
}

export default WhatsappIcon;
