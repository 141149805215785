import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useBasicDetails } from "../Context/BasicDetailsContext";
import { FreeMode, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import RouteLinkWithLoading from "./RouteLinkWithLoading";

function HomepageCategories() {
  const { categories } = useBasicDetails();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, []);

  const slideElem = (
    <div className="card sn-card-preloader">
      <div className="card-img-top" style={{ height: "80px" }}>
        {" "}
      </div>
      <div className="card-body">
        <h5 className="card-title placeholder-title"> </h5>
      </div>
    </div>
  );

  return (
    <section>
      <Container>
        <div className="sn-main-card">
          <div className="sn-main-title">Categories</div>
          <div className="sn-main-content">
            {loading ? (
              <Swiper
                slidesPerView={3}
                spaceBetween={10}
                pagination={false}
                modules={[FreeMode, Pagination]}
                breakpoints={{
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                  },
                }}
                className="mySwiper sn-cat-swipper"
              >
                <SwiperSlide style={{ borderRadius: "10px" }}>
                  {slideElem}
                </SwiperSlide>
                <SwiperSlide style={{ borderRadius: "10px" }}>
                  {slideElem}
                </SwiperSlide>
                <SwiperSlide style={{ borderRadius: "10px" }}>
                  {slideElem}
                </SwiperSlide>
                <SwiperSlide style={{ borderRadius: "10px" }}>
                  {slideElem}
                </SwiperSlide>
                <SwiperSlide style={{ borderRadius: "10px" }}>
                  {slideElem}
                </SwiperSlide>
                <SwiperSlide style={{ borderRadius: "10px" }}>
                  {slideElem}
                </SwiperSlide>
                <SwiperSlide style={{ borderRadius: "10px" }}>
                  {slideElem}
                </SwiperSlide>
              </Swiper>
            ) : (
              <Swiper
                slidesPerView={3}
                spaceBetween={10}
                pagination={false}
                modules={[FreeMode, Pagination]}
                breakpoints={{
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                  },
                }}
                className="mySwiper sn-cat-swipper"
              >
                {categories.map((category, index) => {
                  return (
                    <SwiperSlide className="sn-cat-card" key={index}>
                      <RouteLinkWithLoading to={`/category/${category.slug}`}>
                        <div className="sn-cat-img">
                          <img src={category.image} alt={category.name} />
                        </div>
                        <h4 className="sn-cat-name">{category.name}</h4>
                      </RouteLinkWithLoading>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
}

export default HomepageCategories;
