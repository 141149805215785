import { ArrowBack, Search } from "@mui/icons-material";
import React, { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../../Context/LoadingContext";

function MobileSearch({ ...props }) {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();

  // Function to handle changes in the search input
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    showLoading();
    setTimeout(() => {
      navigate(`/search-results?query=${searchTerm}`);
      hideLoading();
    }, 1000);

    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="searchContainModel"
      centered
      fullscreen={true}
    >
      <Modal.Header className="sn-search-header">
        <Modal.Title id="searchContainModel" className="w-100">
          <InputGroup className="mb-3 d-flex flex-nowrap">
            <Button
              className="btn btn-sm"
              variant="secondary"
              onClick={props.onHide}
            >
              <ArrowBack />
            </Button>
            <Form onSubmit={handleSubmit} className="d-flex w-100">
              <Form.Control
                className="w-100"
                name="query"
                placeholder="Search Product ..."
                aria-label="Search Product ..."
                aria-describedby="basic-addon2"
                value={searchTerm}
                onChange={handleChange}
                style={{ borderRadius: 0 }}
              />
              <Button
                variant="secondary"
                id="button-addon2"
                type="submit"
                style={{ borderRadius: 0 }}
              >
                <Search />
              </Button>
            </Form>
          </InputGroup>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4 className="sn-related-search">Related Search</h4>
        <div className="sn-s-list">
          <RouteLinkWithLoading to={`link1`} className="sn-s-list-l">
            link 1 <OpenInNew className="sn-s-list-l-icn" />
          </RouteLinkWithLoading>
          <RouteLinkWithLoading to={`link1`} className="sn-s-list-l">
            link 1 <OpenInNew className="sn-s-list-l-icn" />
          </RouteLinkWithLoading>
          <RouteLinkWithLoading to={`link1`} className="sn-s-list-l">
            link 1 <OpenInNew className="sn-s-list-l-icn" />
          </RouteLinkWithLoading>
          <RouteLinkWithLoading to={`link1`} className="sn-s-list-l">
            link 1 <OpenInNew className="sn-s-list-l-icn" />
          </RouteLinkWithLoading>
          <RouteLinkWithLoading to={`link1`} className="sn-s-list-l">
            link 1 <OpenInNew className="sn-s-list-l-icn" />
          </RouteLinkWithLoading>
          <RouteLinkWithLoading to={`link1`} className="sn-s-list-l">
            link 1 <OpenInNew className="sn-s-list-l-icn" />
          </RouteLinkWithLoading>
          <RouteLinkWithLoading to={`link1`} className="sn-s-list-l">
            link 1 <OpenInNew className="sn-s-list-l-icn" />
          </RouteLinkWithLoading>
          <RouteLinkWithLoading to={`link1`} className="sn-s-list-l">
            link 1 <OpenInNew className="sn-s-list-l-icn" />
          </RouteLinkWithLoading>
        </div> */}
      </Modal.Body>
    </Modal>
  );
}

export default MobileSearch;
