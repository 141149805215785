// BasicDetailsContext.js

import { createContext, useContext, useState, useEffect } from "react";
import bannerImg1 from "../Assets/banners/webbanner.jpg";
import bannerImg2 from "../Assets/banners/fbbanner.jpg";
import axios from "axios";

const BasicDetailsContext = createContext();

export const useBasicDetails = () => {
  return useContext(BasicDetailsContext);
};

export const BasicDetailsProvider = ({ children }) => {
  const [dataStatus, setDataStatus] = useState(false);
  const [apiUrl, setApiUrl] = useState("https://admin.sakshoppers.com/api/v1/");
  const [logo, setLogo] = useState("");
  const [categories, setCategories] = useState([]);
  const [featureProductsCt, setFeatureProductsCt] = useState([]);
  const [forYouProductsCt, setForYouProductsCt] = useState([]);
  const [toastMessage, setToastMessage] = useState({
    message: "",
    isError: false,
  });
  const productLists = [
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "0",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "0",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
    {
      title: "badgeX-AGE BUZZ Smart Watch For Men and Women (XSW02)",
      slug: "this-is-slug",
      image:
        "https://static-01.daraz.com.np/p/3c41e9a312fb18630888a5267367b047.jpg_750x750.jpg_.webp",
      price: "2000",
      discount: "10",
    },
  ];
  const isMobile = window.innerWidth < 768;
  const banners = [
    {
      image: bannerImg1,
    },
    {
      image: bannerImg2,
    },
  ];
  const currency = "UGX ";

  const updateApiUrl = (newApiUrl) => {
    setApiUrl(newApiUrl);
  };

  const updateLogo = (newLogo) => {
    setLogo(newLogo);
  };
  const getInitData = async () => {
    try {
      const url = apiUrl + "initial-data";
      const response = await axios.get(url);

      const datas = response.data;
      if (datas.status === true) {
        setCategories(datas.categories);
        setFeatureProductsCt(datas.featureProducts);
        setForYouProductsCt(datas.forYouProducts);
      }
      setDataStatus(true);
    } catch (e) {
      setDataStatus(true);
    }
  };

  useEffect(() => {
    if (!dataStatus) {
      getInitData();
    }
  }, [dataStatus]);

  const contextValue = {
    apiUrl,
    logo,
    updateApiUrl,
    updateLogo,
    banners,
    isMobile,
    categories,
    featureProductsCt,
    currency,
    forYouProductsCt,
    productLists,
    dataStatus,
    toastMessage,
    setToastMessage,
  };

  return (
    <BasicDetailsContext.Provider value={contextValue}>
      {children}
    </BasicDetailsContext.Provider>
  );
};
