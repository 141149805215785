import React, { useEffect, useState } from "react";
import { Accordion, ListGroup } from "react-bootstrap";

import { useBasicDetails } from "../../Context/BasicDetailsContext";
import RouteLinkWithLoading from "../../Components/RouteLinkWithLoading";
function DrawerBody({ handleClose }) {
  const { categories } = useBasicDetails();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, []);

  if (loading) {
    // preloader with card structure
    return (
      <div className=" sn-card-preloader" style={{ width: "100%" }}>
        <div className="card-img-top my-2" style={{ height: "50px" }}></div>
        <div className="card-img-top my-2" style={{ height: "50px" }}></div>
        <div className="card-img-top my-2" style={{ height: "50px" }}></div>
        <div className="card-img-top my-2" style={{ height: "50px" }}></div>
        <div className="card-img-top my-2" style={{ height: "50px" }}></div>
        <div className="card-img-top my-2" style={{ height: "50px" }}></div>
        <div className="card-img-top my-2" style={{ height: "50px" }}></div>
        <div className="card-img-top my-2" style={{ height: "50px" }}></div>
      </div>
    );
  }
  const renderCategories = (categories) => {
    if (!categories || categories.length === 0) {
      return null;
    }
    return categories.map((category, index) => (
      <Accordion.Item key={index} eventKey={index.toString()}>
        <Accordion.Header>
          <RouteLinkWithLoading
            to={`/category/${category.slug}`}
            handleClose={handleClose}
          >
            <span className="category-name">{category.name}</span>
          </RouteLinkWithLoading>
        </Accordion.Header>
        <Accordion.Body>
          {category.children && category.children.length > 0 ? (
            <Accordion className="nested-accordion">
              {category.children.map((subCategory, subIndex) =>
                subCategory.second_children &&
                subCategory.second_children.length > 0 ? (
                  <Accordion className="nested-accordion" key={subIndex}>
                    {renderCategories([subCategory])}
                  </Accordion>
                ) : (
                  <ListGroup.Item key={subIndex} className="sn-cat-list-item">
                    <RouteLinkWithLoading
                      to={`/category/${subCategory.slug}`}
                      handleClose={handleClose}
                    >
                      <span className="sub-category-name">
                        {subCategory.name}
                      </span>
                    </RouteLinkWithLoading>
                  </ListGroup.Item>
                )
              )}
            </Accordion>
          ) : (
            ""
          )}
          {category.second_children && category.second_children.length > 0 ? (
            <Accordion className="nested-accordion">
              {category.second_children.map((subCategory, subIndex) =>
                subCategory.second_children &&
                subCategory.second_children.length > 0 ? (
                  <Accordion className="nested-accordion" key={subIndex}>
                    {renderCategories([subCategory])}
                  </Accordion>
                ) : (
                  <ListGroup.Item key={subIndex} className="sn-cat-list-item">
                    <RouteLinkWithLoading
                      to={`/category/${subCategory.slug}`}
                      handleClose={handleClose}
                    >
                      <span className="sub-category-name">
                        {subCategory.name}
                      </span>
                    </RouteLinkWithLoading>
                  </ListGroup.Item>
                )
              )}
            </Accordion>
          ) : (
            ""
          )}
        </Accordion.Body>
      </Accordion.Item>
    ));
  };

  return (
    <div className="drawer-body">
      <Accordion>{renderCategories(categories)}</Accordion>
    </div>
  );
  // {category.catIsOpen ? <ExpandMore /> : <ChevronRight />}
}

export default DrawerBody;
