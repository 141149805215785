import React from "react";
import { Container } from "react-bootstrap";
import { useBasicDetails } from "../Context/BasicDetailsContext";
import BannerCarousel from "./BannerCarousel";
import MegaMenu from "./MegaMenu";

function HomepageHero() {
  const { isMobile } = useBasicDetails();
  return (
    <section className="sn-banner-section">
      <Container>
        <div className="d-flex">
          {!isMobile ? <MegaMenu /> : ""}
          <div className={`sn-banner-slider ${isMobile ? "sn-mob" : ""}`}>
            <BannerCarousel />
          </div>
        </div>
      </Container>
    </section>
  );
}

export default HomepageHero;
