import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Facebook, Instagram, Pinterest, Twitter } from "@mui/icons-material";

import RouteLinkWithLoading from "../../Components/RouteLinkWithLoading";
import logo from "../../Assets/logo/ss logo png-01.png";
import Whitelogo from "../../Assets/logo/ss logo white png.png";
import artelMoneyImg from "../../Assets/img/artel-money.jpg";
import mtnMoneyImg from "../../Assets/img/mtn-money.png";
import { useTheme } from "../../Context/ThemeContext";

const Footer = () => {
  const { isDarkTheme } = useTheme();
  return (
    <footer className="footer-39201">
      <div className="container">
        <div className="row sn-main-foot-block">
          <div className="col-md mb-4 mb-md-0">
            <img
              className="sn-logo"
              src={isDarkTheme ? Whitelogo : logo}
              alt="Logo"
            />
            <div className="mt-3">
              <ul className="list-unstyled">
                <li className="sn-text-light">
                  <strong>Address: </strong> High Street Mbarara City - Uganda
                </li>
                <li className="sn-text-light">
                  <strong>Email: </strong> sales@sakshoppers.com
                </li>
                <li className="sn-text-light">
                  <strong>phone: </strong>{" "}
                  <a className="sn-text-light" href="tel:+256706386558">
                    (+256) 706 386 558
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md mb-4 mb-md-0">
            <h3>About</h3>
            <ul className="list-unstyled nav-links">
              <li>
                <RouteLinkWithLoading to="/about-us">
                  About us
                </RouteLinkWithLoading>
              </li>
              <li>
                <RouteLinkWithLoading to="/faqs">Faqs</RouteLinkWithLoading>
              </li>
              <li>
                <RouteLinkWithLoading to="#">Best sellers</RouteLinkWithLoading>
              </li>
              <li>
                <RouteLinkWithLoading to="#">Blog</RouteLinkWithLoading>
              </li>
              <li>
                <RouteLinkWithLoading to="/contact-us">
                  Contact
                </RouteLinkWithLoading>
              </li>
            </ul>
          </div>
          <div className="col-md mb-4 mb-md-0">
            <h3>Legal</h3>
            <ul className="list-unstyled nav-links">
              <li>
                <RouteLinkWithLoading to="/term-condition">
                  Terms &amp; Conditions
                </RouteLinkWithLoading>
              </li>
              <li>
                <RouteLinkWithLoading to="/privacy-policy">
                  Privacy Policy
                </RouteLinkWithLoading>
              </li>
              <li>
                <RouteLinkWithLoading to="/how-to-buy">
                  How to buy
                </RouteLinkWithLoading>
              </li>
              <li>
                <RouteLinkWithLoading to="/returns-refunds">
                  Returns & Refund
                </RouteLinkWithLoading>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mb-4 mb-md-0">
            <h3>Subscribe</h3>
            <p className="mb-4">Subscribe to our newsletter:</p>
            <form action="#" className="subscribe">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your e-mail"
              />
              <input type="submit" className="btn btn-submit" value="Send" />
            </form>
            <ul className="social list-unstyled mt-3">
              <li>
                <RouteLinkWithLoading to="#">
                  <span className="icon-facebook">
                    <Facebook />
                  </span>
                </RouteLinkWithLoading>
              </li>
              <li>
                <RouteLinkWithLoading to="#">
                  <span className="icon-twitter">
                    <Twitter />
                  </span>
                </RouteLinkWithLoading>
              </li>
              <li>
                <RouteLinkWithLoading to="#">
                  <span className="icon-pinterest">
                    <Pinterest />
                  </span>
                </RouteLinkWithLoading>
              </li>
              <li>
                <RouteLinkWithLoading to="#">
                  <span className="icon-instagram">
                    <Instagram />
                  </span>
                </RouteLinkWithLoading>
              </li>
            </ul>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12">
            <div className="border-top mt-4"></div>
          </div>
          <div className="col-md-6">
            <p>
              <small>
                &copy; {new Date().getFullYear()} Sak Shoppers. All Rights
                Reserved.
              </small>
            </p>
          </div>
          <div className="col-md-6 payment-logos d-flex justify-content-end">
            <strong>Payment Methods: </strong>
            <img
              src={artelMoneyImg}
              alt="Artel Money"
              width="40"
              height="20"
              className="me-2"
            />
            <img
              src={mtnMoneyImg}
              alt="Mtn Money"
              width="40"
              height="20"
              className="me-2"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
