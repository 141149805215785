import React from "react";
import { useBasicDetails } from "../Context/BasicDetailsContext";
import { Col, Container, Row } from "react-bootstrap";
import ProductCard from "../Components/ProductCard";

function ProductList() {
  const { productLists } = useBasicDetails();
  return (
    <section>
      <Container>
        <Row>
          <Col>
            <div className="sn-main-card">
              <div className="sn-main-title">Filter Product</div>
              <div className="sn-main-content"></div>
            </div>
          </Col>
          <Col>
            <div className="sn-main-card">
              <div className="sn-main-title">Product List</div>
              <div className="sn-main-content">
                <Row>
                  {productLists.map((product, index) => (
                    <Col md={3} sm={6} xs={6} key={index}>
                      <ProductCard product={product} />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ProductList;
