import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Dropdown, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../Context/ThemeContext";
import { LightMode, Nightlight } from "@mui/icons-material";
import RouteLinkWithLoading from "../../Components/RouteLinkWithLoading";

function TopHead() {
  const { isDarkTheme, toggleTheme } = useTheme();
  const navigate = useNavigate();
  const handleOnClick = (url) => {
    return navigate(url);
  };
  return (
    <Container>
      <div className="sn-link-list custom-links ms-auto me-2">
        <RouteLinkWithLoading to="#" className="sn-link-item">
          Become a Seller
        </RouteLinkWithLoading>
        <RouteLinkWithLoading to="#" className="sn-link-item">
          Payments & Recharge
        </RouteLinkWithLoading>
        <Dropdown>
          <Dropdown.Toggle className="sn-link-item" id="dropdown-basic">
            Help & Support
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleOnClick("/faqs")}>
              FAQ
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleOnClick("/contact-us")}>
              Contact Us
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Button
          variant={isDarkTheme ? "dark" : "light"}
          className="sn-theme-btn ms-auto"
          onClick={toggleTheme}
        >
          {isDarkTheme ? "Dark Mode " : "Light Mode "}
          {isDarkTheme ? <Nightlight /> : <LightMode />}
        </Button>
      </div>
    </Container>
  );
}

export default TopHead;
