import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useBasicDetails } from "../Context/BasicDetailsContext";
function BannerCarousel() {
  const { banners } = useBasicDetails();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, []);
  if (loading) {
    // preloader with card structure
    return (
      <div
        className="card sn-card-preloader h-100"
        style={{ minHeight: "300px", border: "0", marginTop: "0" }}
      >
        <div className="card-img-top h-100 w-100"></div>
      </div>
    );
  }
  return (
    <Swiper
      className="mySwiper"
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
    >
      {banners.length > 0
        ? banners.map((banner, index) => {
            return (
              <SwiperSlide key={index}>
                <img
                  src={banner.image}
                  alt="Banner"
                  className="sn-banner-image"
                />
              </SwiperSlide>
            );
          })
        : ""}
    </Swiper>
  );
}

export default BannerCarousel;
