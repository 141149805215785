import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../Context/CartContext";
import { useBasicDetails } from "../../Context/BasicDetailsContext";
import { useLoading } from "../../Context/LoadingContext";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useAuth } from "../../Context/AuthContext";
import Spinner from "react-bootstrap/Spinner";
import RouteLinkWithLoading from "../../Components/RouteLinkWithLoading";

function Checkout() {
  const { cartItems, emptyCart } = useCart();
  const { showLoading, hideLoading } = useLoading();
  const { apiUrl, currency } = useBasicDetails();
  const { token, user } = useAuth();
  const navigate = useNavigate();
  // State variables to store form data
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [shippings, setShippings] = useState([]);
  const [shippingId, setShippingId] = useState(null);

  const [promoDiscount, setPromoDiscount] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [promoAmount, setPromoAmount] = useState(0.0);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [promoApplied, setPromoApplied] = useState(false);

  useEffect(() => {
    const promoCodeFromStorage = sessionStorage.getItem("promoCode");
    if (promoCodeFromStorage && promoCodeFromStorage !== "null") {
      setPromoApplied(true);
      setPromoCode(promoCodeFromStorage);
    }
  });

  const handlePromoChange = (discount, code) => {
    sessionStorage.setItem("promoCode", code);
    sessionStorage.setItem("promoDiscount", discount);
    setPromoDiscount(discount);
    setPromoCode(code);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}apply-coupon`,
        { coupon_code: promoCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, data, message } = response.data;
      if (status) {
        sessionStorage.setItem("promoCode", promoCode);
        sessionStorage.setItem("promoDiscount", data.discount);
        setPromoDiscount(data.discount);
        setPromoCode(promoCode);
        setPromoApplied(true);
        setError("");
      } else {
        setError(data.error);
      }
    } catch (error) {
      console.error("Error applying promo code:", error);
      setError("Failed to apply promo code. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  const handleRemovePromo = () => {
    handlePromoChange(0, null);
    setPromoApplied(false);
  };

  // Function to calculate the total price after discount
  // const calculateTotalAfterDiscount = () => {
  //   // Calculate total without promo code discount
  //   let totalWithoutDiscount = cartItems.reduce((total, item) => {
  //     const itemPrice = parseFloat(item.product.price);
  //     const itemDiscount = parseFloat(item.product.discount);
  //     const discountedPrice = itemPrice - (itemPrice * itemDiscount) / 100;
  //     return total + discountedPrice * item.quantity;
  //   }, 0);
  //   // Apply promo code discount if available
  //   if (promoDiscount && promoCode) {
  //     const promoDiscountAmount = (totalWithoutDiscount * promoDiscount) / 100;
  //     totalWithoutDiscount -= promoDiscountAmount;
  //     setPromoAmount(promoDiscountAmount);
  //   }

  //   return totalWithoutDiscount;
  // };

  const calculateTotalWithoutDiscount = useMemo(() => {
    return cartItems.reduce((total, item) => {
      const itemPrice = parseFloat(item.product.price);
      const itemDiscount = parseFloat(item.product.discount);
      const discountedPrice = itemPrice - (itemPrice * itemDiscount) / 100;
      return total + discountedPrice * item.quantity;
    }, 0);
  }, [cartItems]);

  const calculateTotalAfterDiscount = useMemo(() => {
    let total = calculateTotalWithoutDiscount;
    if (promoDiscount && promoCode) {
      const promoDiscountAmount = (total * promoDiscount) / 100;
      total -= promoDiscountAmount;
      setPromoAmount(promoDiscountAmount);
    }

    return total;
  }, [calculateTotalWithoutDiscount, promoDiscount, promoCode]);

  const validateForm = () => {
    const errors = {};
    // Validate first name
    if (!firstName.trim()) {
      errors.firstName = "First name is required";
    }
    // Validate last name
    if (!lastName.trim()) {
      errors.lastName = "Last name is required";
    }
    // Validate email
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      errors.email = "Invalid email address";
    }
    // Validate address
    if (!address.trim()) {
      errors.address = "Address is required";
    }
    // Validate city
    if (!city.trim()) {
      errors.city = "City is required";
    }
    // Validate ZIP code
    if (!zipCode.trim()) {
      errors.zipCode = "ZIP code is required";
    } else if (!/^\d{5}$/.test(zipCode)) {
      errors.zipCode = "Invalid ZIP code";
    }
    // Validate payment method
    if (!paymentMethod.trim()) {
      errors.paymentMethod = "Payment method is required";
    }
    if (!shippingId) {
      errors.shippingId = "Shipping method is required";
    }
    // Return errors object
    return errors;
  };

  // Function to handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      showLoading();
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email,
        address,
        city,
        zip_code: zipCode,
        payment_method: paymentMethod,
        shipping_id: shippingId,
        products: cartItems,
        coupon_code: sessionStorage.getItem("promoCode") || null,
        coupon_discount: sessionStorage.getItem("promoDiscount") || 0,
      };

      console.log(formData);
      // Store form data in session storage
      sessionStorage.setItem("checkoutFormData", JSON.stringify(formData));
      const submitOrder = async () => {
        try {
          const response = await axios.post(apiUrl + "checkout", formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const responseData = response.data;
          if (responseData.status) {
            sessionStorage.setItem(
              "checkout",
              JSON.stringify(responseData.data)
            );
            await emptyCart();
            await handleRemovePromo();
            navigate("/my/invoice");
          } else {
            toast.error("Failed to place order: " + responseData.message);
          }
        } catch (error) {
          // Error occurred while making the request
          console.error("Error placing order:", error);
          toast.error("Failed to place order. Please try again later.");
        } finally {
          hideLoading();
        }
      };

      // Call the submitOrder function to initiate the checkout process
      submitOrder();
      // navigate("/my/invoice");
    } else {
      // Form has errors, display error messages as toasts
      Object.values(validationErrors).forEach((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    // Retrieve form data from session storage
    const storedFormData = sessionStorage.getItem("checkoutFormData");
    if (storedFormData) {
      // Parse the JSON string into an object
      const parsedFormData = JSON.parse(storedFormData);
      // Set the form data state
      setFirstName(parsedFormData.first_name || "");
      setLastName(parsedFormData.last_name || "");
      setEmail(parsedFormData.email || "");
      setAddress(parsedFormData.address || "");
      setCity(parsedFormData.city || "");
      setZipCode(parsedFormData.zip_code || "");
      setPaymentMethod(parsedFormData.payment_method || "");
    }
    const fetchShippings = async () => {
      try {
        const storedShippings = sessionStorage.getItem("shippings");
        if (storedShippings) {
          setShippings(JSON.parse(storedShippings));
        } else {
          const response = await axios.get(apiUrl + "get/shippings");
          const datas = response.data;
          if (datas.status) {
            setShippings(datas.data);
            sessionStorage.setItem("shippings", JSON.stringify(datas.data));
          } else {
            toast.error("Couldn't find shipping");
          }
        }
      } catch (error) {
        toast.error("Couldn't find shipping");
      }
    };
    fetchShippings();
  }, []);
  if (cartItems.length === 0) {
    return (
      <div className="container mt-5 pt-5 text-center">
        <h4>Your cart is empty, please add products to continue checkout.</h4>
        <RouteLinkWithLoading to="/" className="btn btn-secondary">
          Continue Shopping
        </RouteLinkWithLoading>
      </div>
    );
  }
  return (
    <div className="container my-5">
      <h1 className="mb-3 text-bold">Place Your Order</h1>
      <hr />
      <div className="row">
        {/* Checkout form */}
        <div className="col-md-6">
          <h3>Enter Your Details</h3>
          <form onSubmit={handleFormSubmit}>
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="city" className="form-label">
                City
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="zipCode" className="form-label">
                ZIP Code
              </label>
              <input
                type="text"
                className="form-control"
                id="zipCode"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary mt-3">
              Place Order
            </button>
          </form>
        </div>
        {/* Cart summary */}
        <div className="col-md-6">
          <div className="mt-3">
            <div className="card">
              <div className="card-body">
                {sessionStorage.getItem("promoCode") && (
                  <>
                    <h5>Promo Code: {sessionStorage.getItem("promoCode")}</h5>
                    <h5>
                      Promo Discount: {sessionStorage.getItem("promoDiscount")}%
                    </h5>
                    <h5>
                      Promo Amount: {currency}
                      {(calculateTotalAfterDiscount *
                        sessionStorage.getItem("promoDiscount")) /
                        100}
                    </h5>
                  </>
                )}
                <h4>
                  Total (After Discount): {currency}
                  {calculateTotalAfterDiscount.toFixed(2)}
                </h4>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="shipping" className="form-label">
                Select Shipping
              </label>
              <select
                className="form-select"
                id="shipping"
                value={shippingId}
                onChange={(e) => setShippingId(e.target.value)}
                required
              >
                <option value="">Select Shipping Option</option>
                {shippings.map((shipping) => (
                  <option key={shipping.id} value={shipping.id}>
                    {shipping.address} - {currency}
                    {shipping.charge}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="paymentMethod" className="form-label">
                Select Payment Method
              </label>
              <select
                className="form-select"
                id="paymentMethod"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                required
              >
                <option value="">Select Payment Method</option>
                <option value="cod">Cash on delivery</option>
              </select>
            </div>
          </div>
          <h3>Cart Summary</h3>
          <ul className="list-group">
            {cartItems.map((item) => (
              <li key={item.product.slug} className="list-group-item">
                {item.product.title}: {currency}
                <strong>
                  {parseFloat(
                    (item.product.price -
                      (item.product.price * item.product.discount) / 100) *
                      item.quantity
                  ).toFixed(2)}
                </strong>
              </li>
            ))}
          </ul>
          <div className="card mt-3">
            <div className="card-body">
              {loading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <>
                  <label htmlFor="promoCode" className="form-label">
                    Use a promo code?
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Promo Code"
                      aria-label="Promo Code"
                      aria-describedby="button-addon2"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                      disabled={promoApplied}
                    />
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      disabled={promoApplied}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={handleRemovePromo}
                      disabled={!promoApplied}
                    >
                      Remove
                    </button>
                  </div>
                  {error && <div className="text-danger">{error}</div>}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Checkout;
