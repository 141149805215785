import React from "react";
import { Col, Row } from "react-bootstrap";
import ProductListCard from "../../Components/ProductListCard";

function ProductList({ products }) {
  return (
    <Row>
      {products.map((product, productIndex) => {
        return (
          <Col md={12} xs={12} key={productIndex}>
            <ProductListCard product={product} />
          </Col>
        );
      })}
    </Row>
  );
}

export default ProductList;
