import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { useLoading } from "../../Context/LoadingContext";

function HeaderSearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();

  // Function to handle changes in the search input
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    showLoading();
    setTimeout(() => {
      navigate(`/search-results?query=${searchTerm}`);
      hideLoading();
    }, 1000);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          type="text"
          className="w-100 form-control"
          placeholder="Search Product ..."
          value={searchTerm}
          onChange={handleChange}
        />
        <button type="submit" className="search-icon">
          <Search />
        </button>
      </div>
    </form>
  );
}

export default HeaderSearch;
