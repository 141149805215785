import React from "react";
import {
  Nav,
  Navbar,
  OverlayTrigger,
  Container,
  Tooltip,
  Badge,
  Dropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/logo/ss logo png-01.png";
import Whitelogo from "../../Assets/logo/ss logo white png.png";
import { ShoppingCart } from "@mui/icons-material";
import HeaderSearch from "./HeaderSearch";
import { useAuth } from "../../Context/AuthContext";
import { useLoading } from "../../Context/LoadingContext";
import { useCart } from "../../Context/CartContext";
import RouteLinkWithLoading from "../../Components/RouteLinkWithLoading";
import { useTheme } from "../../Context/ThemeContext";

function Header() {
  const { isAuth, logout, user } = useAuth();
  const { showLoading, hideLoading } = useLoading();
  const navigate = useNavigate();
  const { cartItems } = useCart();
  const { isDarkTheme } = useTheme();

  const renderTooltip = (content) => <Tooltip id="tooltip">{content}</Tooltip>;
  const logoutUser = async () => {
    showLoading();
    await logout();
    navigate("/login");
    setTimeout(() => {
      hideLoading();
    }, 1000);
  };
  const handleOnClick = (url) => {
    return navigate(url);
  };
  return (
    <Container>
      <Navbar className="px-3">
        <Navbar.Brand as={RouteLinkWithLoading} to="/">
          <img
            className="sn-logo"
            src={isDarkTheme ? Whitelogo : logo}
            alt="Logo"
          />
        </Navbar.Brand>
        <div className="me-auto w-100">
          <HeaderSearch />
        </div>
        <Nav className="ms-auto">
          {isAuth() ? (
            <Dropdown style={{ paddingTop: "2px", paddingLeft: "5px" }}>
              <Dropdown.Toggle className="sn-link-item" id="dropdown-basic">
                My Account
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleOnClick("/my")}>
                  Account
                </Dropdown.Item>
                {user.role === "admin" ? (
                  <Dropdown.Item href="https://admin.sakshoppers.com">
                    Admin Dashboard
                  </Dropdown.Item>
                ) : (
                  ""
                )}
                <Dropdown.Item onClick={logoutUser}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <>
              <Nav.Link
                className="sn-login-btn"
                as={RouteLinkWithLoading}
                to="/login"
              >
                Login
              </Nav.Link>
              <Nav.Link as={RouteLinkWithLoading} to="/register">
                Register
              </Nav.Link>
            </>
          )}
          <OverlayTrigger
            placement="bottom"
            overlay={renderTooltip("View Cart")}
          >
            <Nav.Link
              className="sn-cart"
              as={RouteLinkWithLoading}
              to="/my/cart"
            >
              <ShoppingCart size={20} />
              <Badge
                bg="danger"
                className="position-absolute top-0 start-100 translate-middle"
              >
                {cartItems.length}
              </Badge>
            </Nav.Link>
          </OverlayTrigger>
        </Nav>
      </Navbar>
    </Container>
  );
}

export default Header;
