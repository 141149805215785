import React, { useEffect, useState } from "react";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import GrainIcon from "@mui/icons-material/Grain";
import { useBasicDetails } from "../Context/BasicDetailsContext";
import RouteLinkWithLoading from "./RouteLinkWithLoading";

function MegaMenu() {
  const { categories } = useBasicDetails();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, []);

  if (loading) {
    // preloader with card structure
    return (
      <div
        className="card sn-card-preloader py-0 my-0 border-0"
        style={{ width: "280px" }}
      >
        <div className="card-img-top my-2" style={{ height: "20px" }}></div>
        <div className="card-img-top my-2" style={{ height: "20px" }}></div>
        <div className="card-img-top my-2" style={{ height: "20px" }}></div>
        <div className="card-img-top my-2" style={{ height: "20px" }}></div>
        <div className="card-img-top my-2" style={{ height: "20px" }}></div>
        <div className="card-img-top my-2" style={{ height: "20px" }}></div>
        <div className="card-img-top my-2" style={{ height: "20px" }}></div>
        <div className="card-img-top my-2" style={{ height: "20px" }}></div>
        <div className="card-img-top my-2" style={{ height: "20px" }}></div>
      </div>
    );
  }
  return (
    <div className="sn-mega-menu">
      <div className="sn-mega-menu-inner">
        <div className="sn-mega-menu-content">
          {categories.map((category, index) => {
            return category.children && category.children.length > 0 ? (
              <div className="sn-mega-menu-item" key={index}>
                <RouteLinkWithLoading
                  className="sn-mega-menu-list-link"
                  to={`/category/${category.slug}`}
                >
                  <div className="sn-mega-cat-img">
                    <img src={category.image} alt={category.name} />
                  </div>{" "}
                  {category.name}
                  <div className="sn-mega-arrow-right">
                    <ArrowRightIcon />
                  </div>
                </RouteLinkWithLoading>
                <div className="sn-mega-menu-sub">
                  {category.children.map((subCat, subIndex) => {
                    return subCat.second_children &&
                      subCat.second_children.length > 0 ? (
                      <div className="sn-mega-menu-item" key={subIndex}>
                        <RouteLinkWithLoading
                          className="sn-mega-menu-list-link"
                          to={`/category/${subCat.slug}`}
                        >
                          <GrainIcon /> {subCat.name}
                          <div className="sn-mega-arrow-right">
                            <ArrowRightIcon />
                          </div>
                        </RouteLinkWithLoading>
                        <div className="sn-mega-menu-sec-sub">
                          {subCat.second_children.map((secSubCat, secIndex) => {
                            return (
                              <div className="sn-mega-menu-item" key={secIndex}>
                                <RouteLinkWithLoading
                                  className="sn-mega-menu-list-link"
                                  to={`/category/${secSubCat.slug}`}
                                >
                                  <GrainIcon /> {secSubCat.name}
                                </RouteLinkWithLoading>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="sn-mega-menu-item" key={subIndex}>
                        <RouteLinkWithLoading
                          className="sn-mega-menu-list-link"
                          to={`/category/${subCat.slug}`}
                        >
                          <GrainIcon /> {subCat.name}
                        </RouteLinkWithLoading>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="sn-mega-menu-item" key={index}>
                <RouteLinkWithLoading
                  className="sn-mega-menu-list-link"
                  to={`/category/${category.slug}`}
                >
                  <div className="sn-mega-cat-img">
                    <img src={category.image} alt={category.name} />
                  </div>
                  {category.name}
                </RouteLinkWithLoading>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MegaMenu;
