import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useBasicDetails } from "./BasicDetailsContext";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const { apiUrl } = useBasicDetails();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      verifyToken(storedToken)
        .then((isValid) => {
          if (!isValid) {
            logout();
          }
          setLoading(false); // Set loading to false once verification is done
        })
        .catch((error) => {
          console.error("Failed to verify token:", error);
          logout();
          setLoading(false); // Set loading to false on error
        });
    } else {
      setLoading(false); // Set loading to false if no token found
    }
  }, []);

  const verifyToken = async (token) => {
    try {
      const response = await axios.post(
        apiUrl + "auth-check",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      if (data.status === true) {
        setUser(data.data.user);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const login = async (email, password) => {
    try {
      const response = await axios.post(apiUrl + "login", { email, password });

      const data = response.data;
      if (data.status === true) {
        const accessToken = data.data.access_token;
        setToken(accessToken);
        setUser(data.data.user);
        localStorage.setItem("token", accessToken);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
    return true;
  };

  const isAuth = () => {
    return user != null && token != null ? true : false;
  };

  return (
    <AuthContext.Provider
      value={{ token, login, logout, isAuth, user, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
