import React from "react";
import { Accordion } from "react-bootstrap";

function Faqs() {
  return (
    <section className="sn-faqs-page pb-5 pt-4">
      <div className="container">
        <h1 className="mb-4">Frequently Asked Questions</h1>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Q1. Are all products on Sakshoppers original and genuine?
            </Accordion.Header>
            <Accordion.Body>
              Yes. We are committed to offering our customers only 100% genuine
              and standardized products. Please send an email to
              info@sakshoppers.com if you think a product listed on our website
              does not meet these standards.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Q2. How can I find the right product on Sakshoppers?
            </Accordion.Header>
            <Accordion.Body>
              You can start by clicking on a category name from the category
              menu on the left side of the website. This shows all the products
              we have within that category. If you know what you are looking
              for, just type the name of the product or brand in our search bar
              at the top of the page and click Search.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Q3. Are there any hidden costs or charges if I order from
              Sakshoppers?
            </Accordion.Header>
            <Accordion.Body>
              There are no hidden costs or charges when you order from
              Sakshoppers. All costs are 100% visible at the end of the checkout
              process.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Q4. Are the prices on Sakshoppers negotiable?
            </Accordion.Header>
            <Accordion.Body>
              Prices on Sakshoppers are not negotiable.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Q5. How do I place an order?</Accordion.Header>
            <Accordion.Body>
              <p>
                Shopping on Sakshoppers is easy! Once you have found the product
                you want to buy, just follow the steps below:
                <br />
                • Click on the product of interest to add this product to your
                cart
                <br />
                • Click on ‘Cart’ in the top right corner
                <br />
                • Enter your Billing information
                <br />
                • Choose your preferred payment option
                <br />• Click on ‘Confirm Order’ to proceed to the payment
                portal and complete your order.
              </p>
              <p>
                Or simply call +256 (0) 706 386 558 to place an order. Once your
                order is placed, we will either automatically confirm it by
                notifying you via email, or we will call you for confirmation in
                case we need more details. Please note that this confirmation is
                a mandatory step before we ship your order. In case you have a
                doubt about whether the confirmation was done or not, do not
                hesitate to call us a few hours after your order placement.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              Q6. How do I know my order has been confirmed?
            </Accordion.Header>
            <Accordion.Body>
              We’ll send you an email and SMS notification once your order has
              been confirmed and as soon as your order left our store.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              Q7. How do I pay on Sakshoppers?
            </Accordion.Header>
            <Accordion.Body>
              You can choose from the two payment methods available on
              Sakshoppers. <br />
              • Cash On Delivery (easy and simple at your doorstep) <br />•
              Mobile money You can find the payment methods during the final{" "}
              <br />
              step of checkout in detail. Do not hesitate to contact our
              Customer Service for more information.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              Q8. Does Sakshoppers offer payment in installments?
            </Accordion.Header>
            <Accordion.Body>
              Sorry, we don’t offer payment in installments at this moment.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </section>
  );
}

export default Faqs;
