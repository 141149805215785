import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { useTheme } from "../../Context/ThemeContext";
import TopHead from "./TopHead";
import MobileHeader from "./MobileHeader";
import { useBasicDetails } from "../../Context/BasicDetailsContext";
import ToastMessage from "../../Components/ToastMessage";
import Footer from "./Footer";
import { useLoading } from "../../Context/LoadingContext";
import FullPageSpinner from "../../Components/FullPageSpinner";
import BottomNavbar from "./BottomNavbar";

function Master() {
  const { isLoading } = useLoading();
  const { isDarkTheme } = useTheme();
  const { isMobile, toastMessage } = useBasicDetails();

  return (
    <>
      <header
        className="sn-header"
        data-bs-theme={isDarkTheme ? "dark" : "light"}
      >
        {isMobile ? (
          <MobileHeader />
        ) : (
          <>
            <TopHead />
            <Header />
          </>
        )}
      </header>
      <main data-bs-theme={isDarkTheme ? "dark" : "light"}>
        {isLoading ? <FullPageSpinner /> : <Outlet />}
      </main>

      {toastMessage.message && (
        <ToastMessage
          message={toastMessage.message}
          isError={toastMessage.isError}
        />
      )}

      <Footer />
      {isMobile ? <BottomNavbar /> : ""}
    </>
  );
}

export default Master;
