import React from "react";

function TermsAndConditions() {
  return (
    <section className="sn-terms-and-conditions  pb-5 pt-4">
      <div className="container">
        <h1 className="mb-4">Terms and Conditions</h1>
        <div class="wpb_wrapper">
          <p>
            <strong>Introduction</strong>
          </p>
          <p>
            Sakshoppers operates an e-commerce platform consisting of a website
            (“marketplace”), together with supporting logistics and easy pay
            supports in the payment infrastructure, for the sale and purchase of
            retail products in Uganda (“territory”). These general terms and
            conditions shall apply to buyers and sellers on the marketplace and
            shall govern your use of the marketplace and related services. By
            using our marketplace, you accept these general terms and conditions
            in full. If you disagree with these general terms and conditions or
            any part of these general terms and conditions, you must not use our
            marketplace.
            <br />
            If you use our marketplace in the course of a business or other
            organizational project, then by so doing you: confirm that you have
            obtained the necessary authority to agree to these general terms and
            conditions; bind both yourself and the person, company or other
            legal entity that operates that business or organizational project,
            to these general terms and conditions; and agree that “you” in these
            general terms and conditions shall reference both the individual
            user and the relevant person, company or legal entity unless the
            context requires otherwise.
          </p>
          <p>
            <strong>Terms and conditions of sale</strong>
            <br />
            The price for a product will be as stated in the relevant product
            listing; the price for the product must include all taxes and comply
            with applicable laws in force from time to time;
          </p>
          <p>
            Delivery charges, packaging charges, handling charges,
            administrative charges, insurance costs, other ancillary costs and
            charges, will only be payable by the buyer if this is expressly and
            clearly stated in the product listing;
          </p>
          <p>
            <strong>Returns and refunds</strong>
            <br />
            Returns of products by buyers shall be managed in accordance with
            the returns page on the marketplace, as may be amended from time to
            time. Acceptance of returns shall be in our discretion, subject to
            compliance with applicable laws of the territory.
          </p>
          <p>
            Refunds in respect of returned products shall be managed in
            accordance with the refunds page on the marketplace, as may be
            amended from time to time. Our rules on refunds shall be exercised
            in our discretion, subject to applicable laws of the territory. We
            may offer refunds, in our discretion:
          </p>
          <p>
            <strong>Making Payments</strong>
            <br />
            You must make payments due under these general terms and conditions
            in accordance with the Payments Information and Guidelines on the
            marketplace.
          </p>
          <p>
            <strong>Use of website</strong>
            <br />
            In this section words “marketplace” and “website” shall be used
            interchangeably to refer to Sakshoppers’s websites.
            <br />
            You may:
            <br />
            (1) view pages from our website in a web browser
            <br />
            (2) download pages from our website for caching in a web browser;
            <br />
            (3) print pages from our website for your own personal and
            non-commercial use, providing that such printing is not systematic
            or excessive;
            <br />
            (4) stream audio and video files from our website using the media
            player on our website; and
            <br />
            (5) use our marketplace services by means of a web browser, subject
            to the other provisions of these general terms and conditions.
            <br />
            (6) Except as expressly permitted by section 8.2 or the other
            provisions of these general terms and conditions, you must not
            download any material from our website or save any such material to
            your computer.
            <br />
            (8) Except as expressly permitted by these general terms and
            conditions, you must not edit or otherwise modify any material on
            our website.
            <br />
            (9) Unless you own or control the relevant rights in the material,
            you must not: republish material from our website (including
            republication on another website)
          </p>
          <p>
            <strong>Law and jurisdiction</strong>
            <br />
            These general terms and conditions shall be governed by and
            construed in accordance with the laws of the territory.
            <br />
            Any disputes relating to these general terms and conditions shall be
            subject to the exclusive jurisdiction of the courts of the
            territory.
          </p>
        </div>
      </div>
    </section>
  );
}

export default TermsAndConditions;
