import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function CategoryProductPreloader() {
  return (
    <Container>
      <Row>
        <Col md={4} lg={3}>
          <div className="sn-main-card">
            <div className="sn-main-title">Filter Product</div>
            <div className="sn-main-content">
              <div
                className="card sn-card-preloader px-2"
                style={{ border: "none" }}
              >
                <div className="card-img-top" style={{ height: "40px" }}>
                  {" "}
                </div>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
              </div>

              <div
                className="card sn-card-preloader px-2"
                style={{ border: "none" }}
              >
                <div className="card-img-top" style={{ height: "40px" }}>
                  {" "}
                </div>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
              </div>

              <div
                className="card sn-card-preloader mt-2 px-2"
                style={{ border: "none" }}
              >
                <div className="card-img-top" style={{ height: "40px" }}>
                  {" "}
                </div>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
              </div>

              <div
                className="card sn-card-preloader mt-2 px-2"
                style={{ border: "none" }}
              >
                <div className="card-img-top" style={{ height: "40px" }}>
                  {" "}
                </div>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
                <h5 className="card-title placeholder-title mt-2"> </h5>
              </div>
            </div>
          </div>
        </Col>
        <Col md={8} lg={9}>
          <div className="sn-main-card">
            <div className="sn-main-title">Product List</div>
            <div className="sn-main-content">
              <Row>
                <Col md={4} col={6}>
                  <div className="card sn-card-preloader">
                    <div className="card-img-top"> </div>
                    <div className="card-body">
                      <h5 className="card-title placeholder-title"> </h5>
                      <p className="card-text placeholder-text"> </p>
                      <div className="d-flex justify-content-between">
                        <span className="placeholder-text"> </span>
                        <span className="placeholder-text"> </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} col={6}>
                  <div className="card sn-card-preloader">
                    <div className="card-img-top"> </div>
                    <div className="card-body">
                      <h5 className="card-title placeholder-title"> </h5>
                      <p className="card-text placeholder-text"> </p>
                      <div className="d-flex justify-content-between">
                        <span className="placeholder-text"> </span>
                        <span className="placeholder-text"> </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} col={6}>
                  <div className="card sn-card-preloader">
                    <div className="card-img-top"> </div>
                    <div className="card-body">
                      <h5 className="card-title placeholder-title"> </h5>
                      <p className="card-text placeholder-text"> </p>
                      <div className="d-flex justify-content-between">
                        <span className="placeholder-text"> </span>
                        <span className="placeholder-text"> </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} col={6}>
                  <div className="card sn-card-preloader">
                    <div className="card-img-top"> </div>
                    <div className="card-body">
                      <h5 className="card-title placeholder-title"> </h5>
                      <p className="card-text placeholder-text"> </p>
                      <div className="d-flex justify-content-between">
                        <span className="placeholder-text"> </span>
                        <span className="placeholder-text"> </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} col={6}>
                  <div className="card sn-card-preloader">
                    <div className="card-img-top"> </div>
                    <div className="card-body">
                      <h5 className="card-title placeholder-title"> </h5>
                      <p className="card-text placeholder-text"> </p>
                      <div className="d-flex justify-content-between">
                        <span className="placeholder-text"> </span>
                        <span className="placeholder-text"> </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} col={6}>
                  <div className="card sn-card-preloader">
                    <div className="card-img-top"> </div>
                    <div className="card-body">
                      <h5 className="card-title placeholder-title"> </h5>
                      <p className="card-text placeholder-text"> </p>
                      <div className="d-flex justify-content-between">
                        <span className="placeholder-text"> </span>
                        <span className="placeholder-text"> </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} col={6}>
                  <div className="card sn-card-preloader">
                    <div className="card-img-top"> </div>
                    <div className="card-body">
                      <h5 className="card-title placeholder-title"> </h5>
                      <p className="card-text placeholder-text"> </p>
                      <div className="d-flex justify-content-between">
                        <span className="placeholder-text"> </span>
                        <span className="placeholder-text"> </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} col={6}>
                  <div className="card sn-card-preloader">
                    <div className="card-img-top"> </div>
                    <div className="card-body">
                      <h5 className="card-title placeholder-title"> </h5>
                      <p className="card-text placeholder-text"> </p>
                      <div className="d-flex justify-content-between">
                        <span className="placeholder-text"> </span>
                        <span className="placeholder-text"> </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} col={6}>
                  <div className="card sn-card-preloader">
                    <div className="card-img-top"> </div>
                    <div className="card-body">
                      <h5 className="card-title placeholder-title"> </h5>
                      <p className="card-text placeholder-text"> </p>
                      <div className="d-flex justify-content-between">
                        <span className="placeholder-text"> </span>
                        <span className="placeholder-text"> </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default CategoryProductPreloader;
