import React from "react";
import { Col, Row } from "react-bootstrap";
import "../Assets/contact.css";

function Contact() {
  return (
    <section className="sn-contact-page">
      <div className="container mb-5">
        <h1>Contact Us</h1>
        <p className="lead">
          We value your feedback because it is very important to us. Should you
          have any inquiries about our products, kindly contact us through the
          channels below.
        </p>

        <Row>
          <Col md={6}>
            <h2>Contact Details</h2>
            <p>
              <strong>Phone:</strong> +256 (0) 706 386 558
            </p>
            <p>
              <strong>Address:</strong> Level 3, Grand West Arcade High Street
              Mbarara City – Uganda
            </p>
            <p>
              <strong>Email: </strong> sales@sakshoppers.com
            </p>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.594634994409!2d30.65952891475341!3d-0.6067109995573687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19d91ba658473423%3A0xc7b1a88ace2bfea5!2sGrand%20west%20Arcade!5e0!3m2!1sen!2sug!4v1641821135385!5m2!1sen!2sug"
                width="100%"
                height="300"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="map address"
              ></iframe>
            </div>
          </Col>
          <Col md={6}>
            <p>
              For inquiries, please email us at{" "}
              <a href="mailto:info@example.com">info@example.com</a> or fill out
              the form below:
            </p>
            <form>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  rows="4"
                  placeholder="Message"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary bg-primary">
                Submit
              </button>
            </form>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Contact;
