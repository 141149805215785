import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../Context/AuthContext";
import { useLoading } from "../../Context/LoadingContext";
import { useNavigate, useLocation } from "react-router-dom";
import RouteLinkWithLoading from "../../Components/RouteLinkWithLoading";

const Login = () => {
  const { showLoading, hideLoading } = useLoading();
  const { token, login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (e) => {
    e.preventDefault();
    showLoading();
    const success = await login(email, password);

    if (success) {
      toast.success("Login successful");
      const redirectTo = location.state?.from?.pathname || "/my";
      navigate(redirectTo);
    } else {
      toast.error("Invalid credentials. Please try again.");
    }
    hideLoading();
  };

  useEffect(() => {
    if (token) {
      const redirectTo = location.state?.from?.pathname || "/my";
      navigate(redirectTo);
    }
  }, [token, navigate, location]);

  return (
    <div className="sn-background-img py-5">
      <div className="container">
        <div className="sn-auth-container">
          <div className="sn-auth-cotain">
            <h1 className="mb-4">Login</h1>
            {token ? (
              <p>You are already logged in. Redirecting...</p>
            ) : (
              <Form onSubmit={handleLogin}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                <Button variant="primary" type="submit">
                  Login
                </Button>
                <p>
                  Don't have an account{" "}
                  <RouteLinkWithLoading
                    to={"/register"}
                    className="d-inline-block"
                  >
                    Create One?
                  </RouteLinkWithLoading>
                </p>
              </Form>
            )}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              pauseOnHover
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
