import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Button,
  Navbar,
  Row,
  Col,
  Offcanvas,
} from "react-bootstrap";

import { useTheme } from "../../Context/ThemeContext";
import { LightMode, Menu, Nightlight, Search } from "@mui/icons-material";
import logo from "../../Assets/logo/ss logo png-01.png";
import darkLogo from "../../Assets/logo/ss logo white png.png";
import MobileSearch from "../Includes/MobileSearch";
import DrawerBody from "../Includes/DrawerBody";
import RouteLinkWithLoading from "../../Components/RouteLinkWithLoading";

function MobileHeader() {
  const { isDarkTheme, toggleTheme } = useTheme();
  const [show, setShow] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="sn-mobile-header">
      <Container>
        <div className="ms-auto me-2">
          <Row>
            <Col className="d-flex">
              <Button onClick={handleShow} className="btn">
                <Menu />
              </Button>
              <Navbar.Brand as={RouteLinkWithLoading} to="/">
                <img
                  className="sn-logo sn-logo-mb"
                  alt="logo"
                  src={isDarkTheme ? darkLogo : logo}
                />
              </Navbar.Brand>
            </Col>
            <Col className="d-flex align-item-end">
              <Button
                variant={isDarkTheme ? "dark" : "light"}
                className="btn ms-auto btn-sm"
                onClick={() => {
                  toggleTheme();
                }}
              >
                {isDarkTheme ? <Nightlight /> : <LightMode />}
              </Button>
              <Button className="btn" onClick={() => setShowSearchBox(true)}>
                <Search />
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header className="sn-drw_head" closeButton>
          <Offcanvas.Title>
            <img className="sn-logo sn-logo-mb" alt="logo" src={darkLogo} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="sn-drawer-body">
          <DrawerBody handleClose={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>
      <MobileSearch
        show={showSearchBox}
        onHide={() => setShowSearchBox(false)}
      />
    </div>
  );
}

export default MobileHeader;
